<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-18 10:47:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-05 20:00:21
-->
<template>
  <div>
    <a-modal title="修改折扣金额" v-model="visible" width="40%" @ok="onConfirm" :maskClosable="false" okText="确定">
      <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{ span: 6 }" :wrapper-col="{ span: 10 }">
        <a-form-model-item label="折扣金额" prop="finalUsedRebateTotal">
          <a-input-number :min="0" :max="totalRebatePrice" style="width: 100%;" :step="1" v-model="rowData.finalUsedRebateTotal"  />
          <span>可修改范围：0 - {{totalRebatePrice}}</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>

export default {

  data() {
    return {
      visible: false,
      rowData: {},
      totalRebatePrice:0,
      // 表单验证
      formRule: {
        totalRebatePrice: [{ required: true, message: '请输入折扣金额', trigger: 'change' }],
      },
    }
  },

  computed: {},

  created() {},

  methods: {
    isShow(totalRebatePrice) {
      this.rowData = {}
      this.visible = true


      this.totalRebatePrice = parseFloat(totalRebatePrice)
      //this.rowData.finalUsedRebateTotal = parseFloat(totalRebatePrice)
      this.$set(this.rowData,'finalUsedRebateTotal', parseFloat(totalRebatePrice))
    },

    onConfirm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.visible = false
        this.$emit('changeFinalRebatePrice',this.rowData)
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>